<template>
<!--  <el-dialog-->
<!--      title="款式图片"-->
<!--      width="65%"-->
<!--      :visible.sync="dialogState"-->
<!--      :modal-append-to-body="false"-->
<!--      @close="close"-->
<!--      center>-->
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button icon="el-icon-picture-outline" @click="drawer = true" type="warning" size="medium">上传图片</el-button>
        </el-col>
        <el-col :span="6">
          <el-button icon="el-icon-picture-outline" @click="uploadVideo.drawerVideo = true" type="warning" size="medium">上传视频</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-collapse style="width:97%;margin: 0 auto"  v-loading="allLoading">
      <el-collapse-item v-for="(item,index) in styleImageList" :key="item.id" :title="item.typeName" :name="index">
        <el-row :gutter="10" :id="'sortRow'+index">
          <el-col :span="12" :class="'sortCol'+index" :imageId="itemChild.id" :sortIndex="itemChild.sortIndex" style="margin: 1% 0;height: 22vw" v-for="(itemChild,indexChild) in item.image" :key="indexChild">
            <div style="position: relative;">
              <i :class="styleInfo.styleImage===itemChild.styleImage?'el-icon-star-on':'el-icon-star-off'"
                 style="font-size: 32px;position: absolute;top:3%;left:2%;z-index: 1;color: white;"
                 @click="mainImage(itemChild.styleImage,indexChild)"></i>
              <i class="el-icon-delete" @click="delImage(itemChild.id,indexChild)"
                 style="font-size: 32px;position: absolute;top:3%; right: 2%; z-index: 999;color: white;"></i>
              <i class="el-icon-more" @click="changeImageTypeShow(itemChild.id,indexChild)"
                 style="font-size: 32px;position: absolute;bottom: 3%;right: 2%; z-index: 999;color: white;"></i>

              <img
                  style="width:100%;height: 100%;" @click="openImage(itemChild.styleImage)" alt=""
                  :src="`https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/${itemChild.styleImage}?imageMogr2/rquality/60`"/>
            </div>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
    <el-collapse style="width:97%;margin: 0 auto" >
      <el-collapse-item   :title="`款式视频`">
        <el-row :gutter="10" >
          <el-col :span="12" :imageId="item.id" style="margin: 1% 0;height: 22vh" v-for="(item,index) in uploadVideo.videoList" :key="item.id">
              <video
                  controls
                  preload="none"
                  playsinline="true"
                  ref="videoRef"
                  controlsList="nodownload disablePictureInPicture noplaybackrate "
                  style="width: 100%;height: 100%;"
                  muted
                  :src="`https://style-video-1304365928.cos.ap-shanghai.myqcloud.com/${item.styleVideo}`"
                  :poster="`https://style-video-1304365928.cos.ap-shanghai.myqcloud.com/${item.styleVideo}?ci-process=snapshot&time=1`"/>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
<!--    <el-row :gutter="50" style="overflow-y: auto;height: 650px;">-->

<!--    </el-row>-->
    <el-drawer
        title="上传图片"
        :visible.sync="drawer"
        :append-to-body="true">
      <el-form label-width="100px" :model="form" :rules="rules" ref="form">
        <el-row>
          <el-col :span="15">
            <el-form-item label="图片类型" prop="imageType">
              <el-select v-model="form.imageType" placeholder="图片类型" clearable>
                <el-option
                    v-for="item in imageTypeIdArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-button type="primary" icon="el-icon-upload2" @click="addImage" :loading="loading">上 传</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-row style="margin-top: 30px">
        <el-col :span="16" :offset="6">
          <div>
            <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :auto-upload="false"
                :on-change="handleAvatarChangeIcon">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
    <el-drawer
        title="上传视频"
        :visible.sync="uploadVideo.drawerVideo"
        :append-to-body="true"
        :before-close="videoDrawerClose">
      <el-row style="text-align: center">
          <el-button type="primary" icon="el-icon-upload2" @click="addVideo" :loading="loading">上 传</el-button>
      </el-row>
      <el-row style="margin-top: 30px;text-align: center">
        <el-col :span="16" :offset="4">
          <div>
            <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :auto-upload="false"
                :on-change="videoChange"
                v-loading="uploadVideo.videoLoading">
              <video
                  v-if="uploadVideo.videoUrl!==''"
                  controls
                  preload="none"
                  playsinline="true"
                  ref="videoRef"
                  :src="uploadVideo.videoUrl"
                  style="object-fit:fill;width: 100%;height: 100%;">
              </video>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
    <el-dialog
        title="更改类型"
        :visible.sync="updateImageType.show"
        width="20%"
        :modal="false">
      <el-select v-model="updateImageType.typeId" placeholder="请选择" @change="imageTypeSelect">
        <el-option
            v-for="item in imageTypeIdArray"
            :key="item.value"
            :label="item.name"
            :value="item.value">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeImageType()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
<!--  </el-dialog>-->
</template>

<script>
export default {
  name: "style-image",
  data() {
    return {
      form: {
        imageType: "",
        styleImage: "",
        tenantCrop: localStorage.getItem("tenantCrop"),
        styleId: this.styleInfo.id
      },
      //图片展开
      collapseActive:[],
      styleImageList: [],
      imageTypeIdArray: [],
      rules: {
        imageType: [
          {required: true, message: '请选择图片类型', trigger: 'change'},
        ],
      },
      imageUrl: "",
      file: {},
      flag: false,
      dialogState: this.state,
      loading: false,
      allLoading: false,
      drawer: false,
      updateImageType:{
        show:false,
        imageId:"",
        typeId:"",
        typeName:"",
        index:""
      },
      uploadVideo:{
        videoList:[],
        drawerVideo:false,
        fileName:"",
        videoFile:{},
        videoUrl:"",
        videoLoading:false,
      },
      timer:"",
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    styleInfo: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.queryStyleImage();
    this.queryStyleVideo();
    this.queryImageTypeIdsArr();
  },
  methods: {
    //添加图片
    addImage() {
      this.$refs['form'].validate(valid=> {
        if (valid) {
          if (!this.imageUrl) {
            this.$message({
              showClose: true,
              message: "请选择图片",
              type: 'warning',
              duration: 1500,
            })
            return
          }
          this.loading = true
          this.uploadStyleImage().then(value => {
            if (!value) {
              this.$message({
                showClose: true,
                message: "图片上传发生错误,请检查后重新上传",
                type: 'error',
                duration: 1500,
              })
            } else {
              this.$axios({
                method: "PUT",
                url: "/styleImage/addStyleImage",
                params: {
                  ...this.form
                }
              }).then(response => {
                let flag = response.data.code === 200
                this.$message({
                  showClose: true,
                  message: flag ? "图片添加成功" :response.data.msg,
                  type: flag? 'success' :'error',
                  duration: 1000,
                })
                if (flag) {
                  this.drawer = false
                  this.queryStyleImage()
                  this.file = {}
                  this.imageUrl = ""
                }
                this.loading = false
              })
            }
          })
        }
      })
    },
    //添加视频
    addVideo(){
      if (this.uploadVideo.videoList.length>=3){
        this.$message.error("最多上传三个视频");
        return;
      }
      if (this.uploadVideo.videoUrl==="") {
        this.$message.error("请先选择视频");
        return;
      }
      this.uploadVideo.videoLoading=true;
      let self=this;
      this.$upload.getTemporaryKey(this.$upload.styleVideo).putObject({
        Bucket: this.$upload.getBucketName(this.$upload.styleVideo), /* 必须 */
        Region: this.$upload.getRegion(),     /* 存储桶所在地域，必须字段 */
        Key: this.uploadVideo.fileName,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: this.uploadVideo.videoFile, // 上传文件对象
      },function(err, data) {
        if (err!==null){
          this.$message.error("上传失败,请刷新重试!")
          return;
        }
        self.$axios({
          method: "PUT",
          url: "/styleVideo/addStyleVideo",
          params: {
            styleId: self.styleInfo.id,
            styleVideo:self.uploadVideo.fileName,
            tenantCrop:self.form.tenantCrop,
          }
        }).then(response => {
          self.queryStyleVideo();
          self.uploadVideo.videoLoading=false;
          if (response.data.code!==200){
            self.$message.success("上传失败,请刷新重试!");
            return
          }
          self.$message.success("上传成功");
          setTimeout(function (){
            self.videoDrawerClose();
          },1500)
        })

      });
    },
    //查询款式图片
    queryStyleImage() {
      this.$axios({
        method: "GET",
        url: "/styleImage/queryStyleImageList",
        params: {
          styleId: this.styleInfo.id
        }
      }).then(response => {
        this.styleImageList = []
        this.styleImageList.push(...this.arrGroupBy(response.data.data));
        this.imageSortableApply();
        for (let index in this.styleImageList){
          this.collapseActive.push(Number(index))
        }
      })
    },
    //查询款式视频
    queryStyleVideo() {
      this.$axios({
        method: "GET",
        url: "/styleVideo/queryStyleVideoList",
        params: {
          styleId: this.styleInfo.id,
          tenantCrop:this.form.tenantCrop,
        }
      }).then(response => {
        this.uploadVideo.videoList=response.data.data;
      })
    },
    //下拉菜单选项
    handleCommand(command) {
      switch (command[0]) {
        case 1:
          this.mainImage(command);
          break;
        case 3:
          this.delImage(command);
          break;
      }
    },

    //设为主图
    mainImage(styleImage,index) {
      this.$confirm("是否将第" + (index + 1) + "张图设为该款式主图", '设置主图', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "PUT",
          url: "/style/editStyle",
          params: {
            id: this.styleInfo.id,
            styleImage: styleImage,
            tenantCrop: this.form.tenantCrop
          }
        }).then(response => {
          response.data.code === 200?this.$message.success("修改成功!"):this.$message.error(response.data.msg);
          this.queryStyleImage();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    changeImageTypeShow(imageId,index){
      this.updateImageType.show=true;
      this.updateImageType.imageId=imageId;
      this.updateImageType.index=index;
    },
    imageTypeSelect(value){
      this.updateImageType.typeName=this.imageTypeIdArray.filter(k=>k.value===value)[0].name;
    },
    //下拉菜单更改图片类型
    changeImageType() {
      this.$confirm("是否将第" + (this.updateImageType.index + 1) + "张图设为“" + this.updateImageType.typeName + "” ?","修改图片类型", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/styleImage/changeImageType",
          params: {
            id: this.updateImageType.imageId,
            imageType: this.updateImageType.typeId,
          }
        }).then(response => {
          response.data.code === 200?this.$message.success("修改图片类型成功!"):this.$message.error(response.data.msg);
          this.queryStyleImage();
          this.updateImageType.show=false;
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //删除款式图
    delImage(imageId,index) {
      this.$confirm("是否将第" + (index + 1) + "张图删除", '删除图片', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/styleImage/deleteStyleImage",
          params: {
            id: imageId
          }
        }).then(response => {
          response.data.code === 200?this.$message.success("删除图片成功"):this.$message.error(response.data.msg);
          this.queryStyleImage();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //放大图片
    openImage(link) {
      this.$viewerApi({
        images: ['https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/' + link],
      })
    },
    //查询款式类型
    queryImageTypeIdsArr() {
      this.$selectUtils.queryStyleImageTypes(0).then(response => {
        this.imageTypeIdArray = JSON.parse(response.data.data);
      })
    },
    //图片根据类型分组
    arrGroupBy(array){
      if (array===undefined||array===null||array===[]||array.length===0) return [];
      //获取去重后的图片类型
      let typeName=[];
      array.map(k=>{return k.imageTypeName}).forEach(k=>{
        if (!typeName.includes(k)){
          typeName.push(k)
        }
      })

      let newArr=[];
      typeName.forEach(k=>{
        let arrItem={typeName:k,image:[]}
        array.forEach(v=>{
          if(k===v.imageTypeName){
            arrItem.image.push(v)
          }
        })
        newArr.push(arrItem)
      })
      return newArr;
    },
    //图片拖拽调用
    imageSortableApply(){
      let imageLength=this.styleImageList.length;
      if (imageLength===0) return;
      let className=[];
      for (let i=0;i<imageLength;i++){
        className.push(`sortRow${i}`)
      }
      let _this=this;
      //判断 每个图片类型的UI是否加载完成
      this.timer=setInterval(function (){
        let imageLength=_this.styleImageList.length;
        let count=0;
        for (let clazzName of className){
          if (document.getElementById(clazzName)!==null) count++;
        }
        if (count===imageLength)_this.imageSortable(className);
      },100)
    },
    //图片拖拽
    imageSortable(className){
      window.clearInterval(this.timer)
      for (let clazzName of className){
        let dragTable = this.$sortable.create(document.getElementById(clazzName), {
          animation: 300, //动画
          // 结束拖动事件
          onEnd: async (e) => {
            let imageObj=[];
            if (e.to.childNodes.length<2) return;
            e.to.childNodes.forEach((value,key)=>{
              imageObj.push({id:value.getAttribute('imageid'),sortIndex:key+1})
            })
            this.allLoading=true;
            this.$axios({
              method: "POST",
              url: "/styleImage/changeSortIndex",
              data: {
                styleImages: imageObj
              }
            }).then(response => {
              let flag=response.data.code===200;
              this.$message({
                showClose: true,
                message: flag?"修改顺序成功":"修改顺序失败",
                type: flag?"success":"warning",
                duration: 1500,
              })
              this.allLoading=false;
            })
          },
        });
      }
      // let sortRow=document.getElementsByClassName("sortRow");

    },
    close: function () {
      this.$emit("close", false);
    },
    handleAvatarChangeIcon(file) {
      this.file = file.raw
      this.imageUrl =  URL.createObjectURL(file.raw)
    },
    videoChange(file) {
      this.uploadVideo.videoFile = file.raw
      this.uploadVideo.videoUrl =  URL.createObjectURL(file.raw);
      this.uploadVideo.fileName=this.$md5(file.name+new Date())+".mp4";
    },
    videoDrawerClose(){
      this.uploadVideo.drawerVideo=false;
      this.uploadVideo.videoFile = {};
      this.uploadVideo.videoUrl =  "";
      this.uploadVideo.fileName="";
    },
    uploadStyleImage: function () {
      return new Promise((resolve, reject) => {
        if (this.imageUrl) {
          this.$upload.clothesImageUpload(this.file)
              .then(response => {
                let data = response.data
                if (data.code === 200) {
                  this.form.styleImage = data.data
                  resolve(true)
                } else {
                  reject(false)
                }
              })
        } else {
          resolve(true)
        }
      })
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  margin-top: 5% !important;
}

</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 280px;
  height: 410px;
  display: block;
}
.sortCol{
  cursor: move;
}
</style>